import React, { useEffect } from "react";
import Collection_category from "../../components/collectrions/collection_category";
import {
  Hero_3,
} from "../../components/component";
import About from '../../components/about';
import Topic from '../../components/topic';
import Speaker from '../../components/speaker';
import Sponsor from '../../components/sponsor';
import Ticket from '../../components/ticket';
import Meta from "../../components/Meta";

const Home_3 = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-black/[.85]");
  }, []);

  return (
    <>
      <Meta title="CoinExpoHub | A Premier Blockchain Conference" />
      <Hero_3 />
      <About />
      <Topic />
      <Speaker />
      <Sponsor />
      <Ticket />
    </>
  );
};

export default Home_3;
