import { speaker_data } from '../data/hero_3_swiper_data'

const Speaker = () => {

    
    return (
        <section id="position-speaker" className="bg-white">
            <div className="item-container">
                <h2 className="section-title text-black font-bold text-center">
                    Meet Our {' '}<span className="text-pink">Speakers</span>
                </h2>
                <div className="hidden sm:block">
                    <div className="grid grid-cols-4 gap-8 mt-8">
                    {
                        speaker_data.map((item) => {
                            const { image, id, name, company } = item;
                            return (
                                <div key={id} className="relative group overflow-hidden shadow-lg speaker-card">
                                    <img className="w-full speaker-img transition-transform duration-1000 transform group-hover:scale-105" src={image} alt="Placeholder Image" />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-100 group-hover:opacity-0 transition-opacity duration-1000 flex justify-center items-center" />
                                    <div className="absolute bottom-0 left-0 w-full bg-gray-900 bg-opacity-50 text-white py-4 px-6">
                                        <div className="text-orange-bright font-pixel font-bold text-2xs mb-1">{ name }</div>
                                        <p className="text-gray-300 text-base">{ company }</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                        
                    </div>
                </div>
                <div className="sm:hidden">
                    <div className="grid grid-cols-2 gap-4 mt-4">
                    {
                        speaker_data.map((item) => {
                            const { image, id, name, company } = item;
                            return (
                                <div key={id} className="relative group overflow-hidden shadow-lg speaker-card">
                                    <img className="w-full speaker-img transition-transform duration-1000 transform group-hover:scale-105" src={image} alt="Placeholder Image" />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-100 group-hover:opacity-0 transition-opacity duration-1000 flex justify-center items-center" />
                                    <div className="absolute bottom-0 left-0 w-full bg-gray-900 bg-opacity-50 text-white py-2 px-3">
                                        <div className="text-orange-bright font-pixel font-bold text-xs mb-1">{ name }</div>
                                        <p className="text-gray-300 text-base">{ company }</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                        
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Speaker;