import CountdownTimer from "../components/countdowntimer";

const Ticket = () => {

    return (
        <div className="ticket-wrap bg-white py-16" style={{backgroundImage: "url('/images/hero/hero_slider_1.jpg')"}}>
            <h3 className="section-title font-bold text-center mb-16">
                Buy {' '}
                <span className="text-pink ">Ticket</span>
            </h3>
            <CountdownTimer className="mb-16"/>
            <div className="mt-16 text-center">
                <button className="bg-main hover:bg-main-hover font-medium text-white py-2 px-8 rounded-full transition duration-300 transform hover:rotate-15">
                    Get Ticket
                </button>
            </div>
        </div>
    )
};

export default Ticket;